import React from 'react';

export default function MicIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_650_2406)">
        <path
          d="M16.0013 18.6667C18.2146 18.6667 19.988 16.88 19.988 14.6667L20.0013 6.66667C20.0013 4.45334 18.2146 2.66667 16.0013 2.66667C13.788 2.66667 12.0013 4.45334 12.0013 6.66667V14.6667C12.0013 16.88 13.788 18.6667 16.0013 18.6667ZM23.068 14.6667C23.068 18.6667 19.6813 21.4667 16.0013 21.4667C12.3213 21.4667 8.93463 18.6667 8.93463 14.6667H6.66797C6.66797 19.2133 10.2946 22.9733 14.668 23.6267V28H17.3346V23.6267C21.708 22.9867 25.3346 19.2267 25.3346 14.6667H23.068Z"
          fill="#18CC14"
        />
      </g>
    </svg>
  );
}
