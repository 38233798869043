import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import queryString from 'query-string';
import { useAppState } from '../../../state';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import EndCallIcon from '../../../icons/EndCallIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { logoutUrl } = useAppState();
  const localParticipant = room!.localParticipant;
  const query = queryString.parse(window.location.search);
  const roomName = query.room;

  const exitRoom = () => {
    if (logoutUrl !== undefined) {
      const sid = localParticipant.sid;
      if (typeof roomName !== 'string') return;
      fetch(`/api/room/disconnect?name=${encodeURIComponent(roomName)}&sid=${encodeURIComponent(sid)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(state => console.log(state));
      const withParamLogoutUrl = logoutUrl + '?logout=manual';
      window.location.href = withParamLogoutUrl;
    } else if (query.type === 'guest' && logoutUrl !== undefined) {
      const withParamLogoutUrl = logoutUrl + '?logout=manual';
      window.location.href = withParamLogoutUrl;
    } else {
      room!.disconnect();
    }
  };

  return (
    <>
      <Button
        onClick={exitRoom}
        className={clsx(classes.button, props.className)}
        data-cy-disconnect
        startIcon={<EndCallIcon />}
      >
        退出する
      </Button>
    </>
  );
}
