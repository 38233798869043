import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8 20.275C13.6 20.075 13.504 19.8333 13.512 19.55C13.5207 19.2667 13.625 19.025 13.825 18.825L15.65 17H8C7.71667 17 7.47933 16.904 7.288 16.712C7.096 16.5207 7 16.2833 7 16C7 15.7167 7.096 15.479 7.288 15.287C7.47933 15.0957 7.71667 15 8 15H15.65L13.8 13.15C13.6 12.95 13.5 12.7127 13.5 12.438C13.5 12.1627 13.6 11.925 13.8 11.725C14 11.525 14.2377 11.425 14.513 11.425C14.7877 11.425 15.025 11.525 15.225 11.725L18.8 15.3C18.9 15.4 18.971 15.5083 19.013 15.625C19.0543 15.7417 19.075 15.8667 19.075 16C19.075 16.1333 19.0543 16.2583 19.013 16.375C18.971 16.4917 18.9 16.6 18.8 16.7L15.2 20.3C15.0167 20.4833 14.7877 20.575 14.513 20.575C14.2377 20.575 14 20.475 13.8 20.275ZM9 25C8.45 25 7.979 24.8043 7.587 24.413C7.19567 24.021 7 23.55 7 23V20C7 19.7167 7.096 19.479 7.288 19.287C7.47933 19.0957 7.71667 19 8 19C8.28333 19 8.521 19.0957 8.713 19.287C8.90433 19.479 9 19.7167 9 20V23H23V9H9V12C9 12.2833 8.90433 12.5207 8.713 12.712C8.521 12.904 8.28333 13 8 13C7.71667 13 7.47933 12.904 7.288 12.712C7.096 12.5207 7 12.2833 7 12V9C7 8.45 7.19567 7.979 7.587 7.587C7.979 7.19567 8.45 7 9 7H23C23.55 7 24.021 7.19567 24.413 7.587C24.8043 7.979 25 8.45 25 9V23C25 23.55 24.8043 24.021 24.413 24.413C24.021 24.8043 23.55 25 23 25H9Z"
        fill="#F4F6F9"
      />
    </svg>
  );
}
