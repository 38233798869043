import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#141619',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 'calc(var(--rem) * 1.6)',
      padding: 'calc(var(--rem) * 0.8) calc(var(--rem) * 1.6)',
      paddingRight: 'calc(var(--rem) * 0.8)',
    },
  },
  roomName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 'calc(var(--rem) * 1.4)',
    whiteSpace: 'nowrap',
    color: '#fff',
  },
  contentRight: {
    display: 'flex',
    alignItems: 'center',
    gap: 'calc(var(--rem) * 0.8)',
  },
  endCallButton: {
    height: '100%',
    fontSize: 'calc(var(--rem) * 1.2)',
    whiteSpace: 'nowrap',
    padding: '0 0.6em',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(var(--rem) * 2.8)',
      maxWidth: 'calc(var(--rem) * 2.8)',
      height: '100%',
      padding: '0',
      '& *': {
        lineHeight: 1,
      },
      '& .MuiButton-label': {
        margin: 0,
        '& .MuiButton-startIcon': {
          margin: 0,
          '& svg': {
            width: '100%',
            hegith: 'auto',
          },
        },
      },
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <div className={classes.container}>
      <div className={classes.roomName}>{room!.name}</div>
      <div className={classes.contentRight}>
        <EndCallButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </div>
  );
}
