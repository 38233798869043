import React, { useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden, styled } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import { useAppState, UserTypes } from '../../state';

/* 追加読み込み */
import { isSupported } from '@twilio/video-processors';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import BackgroundIcon from '../../icons/BackgroundIcon';
export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: '#fff',
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `calc(var(--rem) * ${theme.footerHeight / 10})`,
      position: 'fixed',
      display: 'flex',
      padding: 'calc(var(--rem) * 1.3) calc(var(--rem) * 1.6)',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        gap: 'calc(var(--rem) * 0.4)',
        height: `${theme.mobileFooterHeight}px`,
        padding: 'calc(var(--rem) * 0.8)',
      },
      '& > *': {
        flex: 1,
      },
      '& .toggleButton': {
        color: '#fff',
        width: 'calc(var(--rem) * 9.8)',
        padding: 'calc(var(--rem) * 0.5) 0 calc(var(--rem) * 0.3)',
        background: 'transparent',
        borderRadius: 'calc(var(--rem) * 0.4)',
        [theme.breakpoints.down('sm')]: {
          flex: 1,
          width: 'auto',
        },
        '&:hover': {
          opacity: 0.8,
        },
        '&.Mui-disabled': {
          opacity: 0.1,
        },
        '& .MuiButton-label': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 'calc(var(--rem) * 1)',
          lineHeight: 1.8,
          [theme.breakpoints.down('sm')]: {
            fontSize: 'calc(var(--rem) * 0.9)',
          },
          '& .MuiButton-startIcon': {
            width: 'calc(var(--rem) * 3.2)',
            margin: '0 0 calc(var(--rem) * -0.4)',
            [theme.breakpoints.down('sm')]: {
              width: 'calc(var(--rem) * 2.4)',
            },
            '& svg': {
              width: '100%',
              height: 'auto',
            },
          },
        },
      },
    },
    containerLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: 'calc(var(--rem) * 0.8)',
      [theme.breakpoints.down('sm')]: {
        gap: 'calc(var(--rem) * 0.4)',
      },
      '& .toggleButton': {
        background: '#192532',
      },
    },
    containerCenter: {
      display: 'flex',
      justifyContent: 'center',
      gap: 'calc(var(--rem) * 0.8)',
      [theme.breakpoints.down('sm')]: {
        gap: 'calc(var(--rem) * 0.4)',
      },
      '& .toggleButton': {
        '&:hover': {
          background: '#192532',
        },
      },
    },
    containerRight: {
      display: 'flex',
      justifyContent: 'center',
      gap: 'calc(var(--rem) * 0.8)',
      '& .toggleButton': {
        background: '#CC1414',
      },
    },
    toggleButtons: {
      display: 'flex',
      gap: 'calc(var(--rem) * 0.8)',
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `calc(var(--rem) * ${theme.footerHeight / 10})`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const { userType } = useAppState();
  const isReconnecting = roomState === 'reconnecting';
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();
  const participants = useParticipants();

  /* 追記 */
  const { setIsChatWindowOpen } = useChatContext();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">画面共有中</Typography>
          <Button onClick={() => toggleScreenShare()}>共有の停止</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <div className={classes.containerLeft}>
          <ToggleAudioButton disabled={isReconnecting} className={'toggleButton'} />
          <ToggleVideoButton disabled={isReconnecting} className={'toggleButton'} />
        </div>
        <div className={classes.containerCenter}>
          {isSupported && (
            <Button
              className={'toggleButton'}
              onClick={() => {
                setIsBackgroundSelectionOpen(true);
                setIsChatWindowOpen(false);
                setMenuOpen(false);
              }}
              startIcon={<BackgroundIcon />}
            >
              背景エフェクト
            </Button>
          )}
          {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
          {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
          {userType !== UserTypes.viewer && (
            <div className={classes.toggleButtons}>
              <Hidden smDown>
                <Menu buttonClassName={'toggleButton'} />
              </Hidden>
            </div>
          )}
        </div>
        {/* 
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                {room!.name} | {participants.length + 1} participant{participants.length ? 's' : ''}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        */}
        <Hidden smDown>
          <div className={classes.containerRight}>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton className={'toggleButton'} />
              </Grid>
            </Grid>
          </div>
        </Hidden>
      </footer>
    </>
  );
}
