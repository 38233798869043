import React from 'react';

export default function ChatIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 19H17.025C17.3083 19 17.5417 18.904 17.725 18.712C17.9083 18.5207 18 18.2833 18 18C18 17.7167 17.9043 17.479 17.713 17.287C17.521 17.0957 17.2833 17 17 17H10.975C10.6917 17 10.4583 17.0957 10.275 17.287C10.0917 17.479 10 17.7167 10 18C10 18.2833 10.0957 18.5207 10.287 18.712C10.479 18.904 10.7167 19 11 19ZM11 16H21.025C21.3083 16 21.5417 15.904 21.725 15.712C21.9083 15.5207 22 15.2833 22 15C22 14.7167 21.904 14.479 21.712 14.287C21.5207 14.0957 21.2833 14 21 14H10.975C10.6917 14 10.4583 14.0957 10.275 14.287C10.0917 14.479 10 14.7167 10 15C10 15.2833 10.0957 15.5207 10.287 15.712C10.479 15.904 10.7167 16 11 16ZM11 13H21.025C21.3083 13 21.5417 12.9043 21.725 12.713C21.9083 12.521 22 12.2833 22 12C22 11.7167 21.904 11.479 21.712 11.287C21.5207 11.0957 21.2833 11 21 11H10.975C10.6917 11 10.4583 11.0957 10.275 11.287C10.0917 11.479 10 11.7167 10 12C10 12.2833 10.0957 12.521 10.287 12.713C10.479 12.9043 10.7167 13 11 13ZM6 24.575V9C6 8.45 6.196 7.979 6.588 7.587C6.97933 7.19567 7.45 7 8 7H24C24.55 7 25.021 7.19567 25.413 7.587C25.8043 7.979 26 8.45 26 9V21C26 21.55 25.8043 22.021 25.413 22.413C25.021 22.8043 24.55 23 24 23H10L7.7 25.3C7.38333 25.6167 7.02067 25.6873 6.612 25.512C6.204 25.3373 6 25.025 6 24.575Z"
        fill="#F4F6F9"
      />
    </svg>
  );
}
