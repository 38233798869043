import React from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden, Switch, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState, UserTypes } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useKrispToggle } from '../../../hooks/useKrispToggle/useKrispToggle';
import SmallCheckIcon from '../../../icons/SmallCheckIcon';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import { Transform } from 'stream';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontWeight: 'bold',
    fontSize: 'calc(var(--rem) * 3.2)',
    lineHeight: '1.8',
    textAlign: 'center',
    marginBottom: 'calc(var(--rem) * 2.4)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'calc(var(--rem) * 2.4)',
    },
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    color: '#fff',
    width: 'calc(var(--rem) * 8.8)',
    height: 'calc(var(--rem) * 5.4)',
    padding: '0',
    background: '#192532',
    borderRadius: 'calc(var(--rem) * 0.4)',
    '&:hover': {
      opacity: 0.8,
      background: '#192532',
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 'calc(var(--rem) * 1)',
      lineHeight: 1.8,
      marginTop: 'calc(var(--rem) * 0.3)',
      '& .MuiButton-startIcon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(var(--rem) * 3.2)',
        margin: 0,
        '& svg': {
          width: 'auto',
          height: 'calc(var(--rem) * 2.5)',
          objectFit: 'contain',
        },
      },
    },
  },
  boxContainer: {},
  localPreviewContainer: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 'calc(var(--rem) * 0.4)',
    [theme.breakpoints.down('sm')]: {
      /* padding: '0 2.5em', */
    },
  },
  toggleButtons: {
    transform: 'translateX(-50%)',
    position: 'absolute',
    zIndex: 10,
    bottom: '1.7rem',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    gap: 'calc(var(--rem) * 0.8)',
    [theme.breakpoints.down('sm')]: {
      transform: 'unset',
      position: 'static',
      marginTop: 'calc(var(--rem) * 2.4)',
    },
    '& .MuiButtonBase-root': {
      flex: 1,
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'calc(var(--rem) * 2.4)',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
    '& .MuiButton-outlinedPrimary': {
      width: '100%',
      maxWidth: 'calc(var(--rem) * 39)',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  toolTipContainer: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '0.3em',
    },
  },
}));

interface DeviceSelectionScreenProps {
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, roomName, userIdentity, accessHash, isKrispEnabled, isKrispInstalled } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const { toggleKrisp } = useKrispToggle();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    if (!userIdentity || !roomName || !accessHash) {
      throw new Error('userIdentity or roomName or accessHash is Undefined.');
    }

    getToken(userIdentity, roomName, accessHash).then(({ token, connect_type }) => {
      //, default_expand_time
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            通話に参加中
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <div>
      <Typography variant="h5" className={classes.gutterBottom}>
        通話の準備はできましたか？
        {/* Join {roomName} */}
      </Typography>

      <div className={classes.boxContainer}>
        <div className={classes.localPreviewContainer}>
          <LocalVideoPreview identity={userIdentity || 'You'} />
          <div className={classes.toggleButtons}>
            {/* <Hidden smDown> */}
            <ToggleAudioButton
              className={classes.deviceButton}
              disabled={userIdentity === UserTypes.viewer || disableButtons}
            />
            <ToggleVideoButton
              className={classes.deviceButton}
              disabled={userIdentity === UserTypes.viewer || disableButtons}
            />
            {/* </Hidden> */}
          </div>
        </div>
        <div className={classes.mobileButtonBar}>
          {/* <Hidden mdUp>
            <ToggleAudioButton
              className={classes.mobileButton}
              disabled={userIdentity === UserTypes.viewer || disableButtons}
            />
            <ToggleVideoButton
              className={classes.mobileButton}
              disabled={userIdentity === UserTypes.viewer || disableButtons}
            />
          </Hidden> */}
          <SettingsMenu mobileButtonClass={classes.mobileButton} />
        </div>

        {/*
        {isKrispInstalled && (
          <Grid item md={12} sm={12} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: '1em' }}
            >
              <div className={classes.toolTipContainer}>
                <Typography variant="subtitle2">ノイズキャンセリング</Typography>
                <Tooltip
                  title="マイクからのバックグラウンドノイズを抑制します"
                  interactive
                  leaveDelay={250}
                  leaveTouchDelay={15000}
                  enterTouchDelay={0}
                >
                  <div>
                    <InfoIconOutlined />
                  </div>
                </Tooltip>
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!isKrispEnabled}
                    checkedIcon={<SmallCheckIcon />}
                    disableRipple={true}
                    onClick={toggleKrisp}
                  />
                }
                label={isKrispEnabled ? '有効' : '無効'}
                style={{ marginRight: 0 }}
                // Prevents <Switch /> from being temporarily enabled (and then quickly disabled) in unsupported browsers after
                // isAcquiringLocalTracks becomes false:
                disabled={isKrispEnabled && isAcquiringLocalTracks}
              />
            </Grid>
          </Grid>
        )}
        */}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'calc(var(--rem) * 2.4)' }}>
          <Button variant="contained" color="primary" data-cy-join-now onClick={handleJoin} disabled={disableButtons}>
            通話を始める
          </Button>
        </div>
        {/* 
        <Grid item md={12} sm={12} xs={12}>
          <Grid container direction="row" alignItems="center" style={{ marginTop: '1em' }}>
            <Hidden smDown>
              <Grid item md={7} sm={12} xs={12}>
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
              </Grid>
            </Hidden>
            <Grid item md={5} sm={12} xs={12}>
              <div className={classes.joinButtons}>
                <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
                  キャンセル
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        */}
      </div>
    </div>
  );
}
