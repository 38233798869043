import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 24C8.45 24 7.97933 23.8043 7.588 23.413C7.196 23.021 7 22.55 7 22V10C7 9.45 7.196 8.97933 7.588 8.588C7.97933 8.196 8.45 8 9 8H21C21.55 8 22.021 8.196 22.413 8.588C22.8043 8.97933 23 9.45 23 10V14.5L26.15 11.35C26.3 11.2 26.4793 11.1623 26.688 11.237C26.896 11.3123 27 11.4667 27 11.7V20.3C27 20.5333 26.896 20.6873 26.688 20.762C26.4793 20.8373 26.3 20.8 26.15 20.65L23 17.5V22C23 22.55 22.8043 23.021 22.413 23.413C22.021 23.8043 21.55 24 21 24H9Z"
        fill="#18CC14"
      />
    </svg>
  );
}
