import React from 'react';

export default function ScreenShareIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 18C13.2833 18 13.521 17.904 13.713 17.712C13.9043 17.5207 14 17.2833 14 17V16C14 15.7167 14.096 15.479 14.288 15.287C14.4793 15.0957 14.7167 15 15 15H17V15.8C17 16.0333 17.1 16.1917 17.3 16.275C17.5 16.3583 17.6833 16.3167 17.85 16.15L19.65 14.35C19.6833 14.3167 19.7333 14.2 19.8 14C19.8 13.9667 19.75 13.85 19.65 13.65L17.85 11.85C17.6833 11.6833 17.5 11.6417 17.3 11.725C17.1 11.8083 17 11.9667 17 12.2V13H15C14.1667 13 13.4583 13.2917 12.875 13.875C12.2917 14.4583 12 15.1667 12 16V17C12 17.2833 12.096 17.5207 12.288 17.712C12.4793 17.904 12.7167 18 13 18ZM8 22C7.45 22 6.97933 21.8043 6.588 21.413C6.196 21.021 6 20.55 6 20V9C6 8.45 6.196 7.979 6.588 7.587C6.97933 7.19567 7.45 7 8 7H24C24.55 7 25.021 7.19567 25.413 7.587C25.8043 7.979 26 8.45 26 9V20C26 20.55 25.8043 21.021 25.413 21.413C25.021 21.8043 24.55 22 24 22H8ZM6 25C5.71667 25 5.47933 24.904 5.288 24.712C5.096 24.5207 5 24.2833 5 24C5 23.7167 5.096 23.4793 5.288 23.288C5.47933 23.096 5.71667 23 6 23H26C26.2833 23 26.5207 23.096 26.712 23.288C26.904 23.4793 27 23.7167 27 24C27 24.2833 26.904 24.5207 26.712 24.712C26.5207 24.904 26.2833 25 26 25H6Z"
        fill="#F4F6F9"
      />
    </svg>
  );
}
