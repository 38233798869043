import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import { findParticipantsWithoutViewer, isViewer } from '../../utils';
import { useAppState, UserTypes } from '../../state';

export default function useParticipants() {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  // 初期状態でのviewerを除外
  const participantsWithoutViewer = findParticipantsWithoutViewer(Array.from(room?.participants.values() ?? []));
  const { userType } = useAppState();
  const [participants, setParticipants] = useState(participantsWithoutViewer);

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker && !isViewer(participant)),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    // participantの更新時もviewerを除外
    if (room) {
      const participantConnected = (participant: RemoteParticipant) => {
        console.log('他のユーザーが参加しました');
        setParticipants(prevParticipants => {
          return userType !== UserTypes.viewer
            ? findParticipantsWithoutViewer([...prevParticipants, participant] ?? [])
            : [...prevParticipants, participant] ?? [];
        });
      };
      const participantDisconnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => {
          return prevParticipants.filter(p => p !== participant);
        });
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);
  return userType !== UserTypes.viewer ? findParticipantsWithoutViewer(participants) : participants;
}
