import React from 'react';

export default function VideoOffIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.8508 26.655L6.32578 8.13C6.14245 7.94666 6.05078 7.72166 6.05078 7.455C6.05078 7.18833 6.15078 6.955 6.35078 6.755C6.53411 6.57166 6.76745 6.48 7.05078 6.48C7.33411 6.48 7.56745 6.57166 7.75078 6.755L26.2758 25.28C26.4591 25.4633 26.5508 25.6883 26.5508 25.955C26.5508 26.2217 26.4508 26.455 26.2508 26.655C26.0674 26.8383 25.8341 26.93 25.5508 26.93C25.2674 26.93 25.0341 26.8383 24.8508 26.655ZM23.0008 19.18L11.8258 8.005H21.0008C21.5508 8.005 22.0218 8.201 22.4138 8.593C22.8051 8.98433 23.0008 9.455 23.0008 10.005V14.505L26.1508 11.355C26.3008 11.205 26.4801 11.1673 26.6888 11.242C26.8968 11.3173 27.0008 11.4717 27.0008 11.705V20.305C27.0008 20.5383 26.8968 20.6923 26.6888 20.767C26.4801 20.8423 26.3008 20.805 26.1508 20.655L23.0008 17.505V19.18ZM9.00078 8.005L23.0008 22.005C22.9841 22.5383 22.7758 23.005 22.3758 23.405C21.9758 23.805 21.5091 24.005 20.9758 24.005H8.97578C8.42578 24.005 7.95511 23.8093 7.56378 23.418C7.17178 23.026 6.97578 22.555 6.97578 22.005V10.005C6.97578 9.47166 7.17978 9.005 7.58778 8.605C7.99645 8.205 8.46745 8.005 9.00078 8.005Z"
        fill="#475666"
      />
    </svg>
  );
}
