import React from 'react';

export default function MicOffIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_570_1595)">
        <path
          d="M25.3333 14.6667H23.0667C23.0667 15.6533 22.8533 16.5733 22.4933 17.4L24.1333 19.04C24.88 17.7333 25.3333 16.2533 25.3333 14.6667ZM19.9733 14.8933C19.9733 14.8133 20 14.7467 20 14.6667V6.66667C20 4.45334 18.2133 2.66667 16 2.66667C13.7867 2.66667 12 4.45334 12 6.66667V6.90667L19.9733 14.8933ZM5.69333 4.00001L4 5.69334L12.0133 13.7067V14.6667C12.0133 16.88 13.7867 18.6667 16 18.6667C16.2933 18.6667 16.5867 18.6267 16.8667 18.56L19.08 20.7733C18.1333 21.2133 17.08 21.4667 16 21.4667C12.32 21.4667 8.93333 18.6667 8.93333 14.6667H6.66667C6.66667 19.2133 10.2933 22.9733 14.6667 23.6267V28H17.3333V23.6267C18.5467 23.4533 19.6933 23.0267 20.72 22.4267L26.3067 28L28 26.3067L5.69333 4.00001Z"
          fill="#475666"
        />
      </g>
    </svg>
  );
}
