import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase/auth';
import queryString from 'query-string';

export const UserTypes = {
  provider: 'provider',
  guest: 'guest',
  viewer: 'viewer',
} as const;
type userTypes = typeof UserTypes[keyof typeof UserTypes];

export const ConnectTypes = {
  waiting: 'waiting',
  reserve: 'reserve',
  event: 'event',
  greeting: 'greeting',
} as const;
type connectTypes = typeof ConnectTypes[keyof typeof ConnectTypes];

export const MESSAGE_CODES = {
  NONE: '',
  BEFORE_10_MIN: '1001', // 10分前通知
  PROVIDER_EXPAND_SUCCESS: '1002',
  PROVIDER_EXPAND_FAILED: '1003',
  PROVIDER_EXPAND_CANCEL: '1004',
  GUEST_RECEIVED_EXPAND_REQUEST: '2001', // 延長依頼を受信
  GUEST_EXPAND_SUCCESS: '2002',
  GUEST_EXPAND_FAILED: '2003',
} as const;
type MessageCodes = typeof MESSAGE_CODES[keyof typeof MESSAGE_CODES];

export interface TokenResponse {
  //room_type: RoomType;
  //default_expand_price: number;
  //default_expand_time?: number | undefined;
  room_sid: string;
  token: string;
  type: userTypes;
  user_name: string;
  connect_type: connectTypes;
  payment: boolean;
  logout_url: string;
  access_hash: string;
}

export interface StatusResponse {
  message_code: MessageCodes;
  message_text: string;
  room: string;
  type: userTypes;
  time?: number;
  price?: number;
}

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, hash: string, passcode?: string): Promise<TokenResponse>;
  getMessage(): Promise<StatusResponse>;
  postExpandOffer(room: string, expand_time: number, expand_price: number): Promise<any>;
  postExpandAccept(room: string, isCancel: boolean): Promise<any>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  createRoom?: boolean;
  setCreateRoom?(createRoom: boolean): void;
  createConversation?: boolean;
  setCreateConversation?(createConversation: boolean): void;
  maxParticipants?: number;
  setMaxParticipants?(maxParticipants: number): void;
  recording?: boolean;
  setRecording?(recording: boolean): void;
  duration?: number;
  setDuration?(duration: number): void;
  roomName?: string;
  setRoomName(roomName: string): void;
  userIdentity?: string;
  setUserIdentity(roomName: string): void;
  accessHash?: string;
  setAccessHash(accessHash: string): void;
  userId?: number;
  setUserId(userId: number): void;
  userType?: userTypes;
  setUserType(userType: userTypes): void;
  connectType?: connectTypes;
  setConnectType(connectType: connectTypes): void;
  payment?: boolean;
  setPayment?(payment: boolean): void;
  expandTime?: number;
  setExpandTime(expandTime: number): void;
  defaultExpandTime?: number;
  setDefaultExpandTime(expandTime: number): void;
  expandPrice?: number;
  setExpandPrice(expandPrice: number): void;
  defaultExpandPrice?: number;
  setDefaultExpandPrice(expandPrice: number): void;
  requestExpandTime?: number;
  setRequestExpandTime(requestExpandTime: number): void;
  requestExpandPrice?: number;
  setRequestExpandPrice(requestExpandPrice: number): void;
  logoutUrl?: string;
  setLogoutUrl(logoutUrl: string): void;
  messageCode?: MessageCodes;
  setMessageCode(messageCode: MessageCodes): void;
  message?: string;
  setMessage(message: string): void;
  isShowProviderConfirm: boolean;
  setIsShowProviderConfirm(isShow: boolean): void;
  isShowProviderConfirmDone: boolean;
  setIsShowProviderConfirmDone(isShow: boolean): void;
  isShowCustomerConfirmDone: boolean;
  setIsShowCustomerConfirmDone(isShow: boolean): void;
  isShowLast10Min: boolean;
  setIsShowLast10Min(isShow: boolean): void;
  isShowConfirmExpand: boolean;
  setIsShowConfirmExpand(isShow: boolean): void;
  isShowProviderConfirmExpandSuccess: boolean;
  setIsShowProviderConfirmExpandSuccess(isShow: boolean): void;
  isShowProviderConfirmExpandCancel: boolean;
  setIsShowProviderConfirmExpandCancel(isShow: boolean): void;
  isShowGuestConfirmExpandSuccess: boolean;
  setIsShowGuestConfirmExpandSuccess(isShow: boolean): void;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );
  const [createRoom, setCreateRoom] = useState(true);
  const [createConversation, setCreateConversation] = useState(true);
  const [maxParticipants, setMaxParticipants] = useState(2);
  const [recording, setRecording] = useState(true);
  const [duration, setDuration] = useState(14400);
  const [roomName, setRoomName] = useState<string | null>(null);
  const [userIdentity, setUserIdentity] = useState<string | null>(null);
  const [accessHash, setAccessHash] = useState<string | null>(null);
  // const [userId, setUserId] = useState<userTypes | null>(null);
  const [userType, setUserType] = useState<userTypes | null>(null);
  const [connectType, setConnectType] = useState<connectTypes | null>(null);
  const [payment, setPayment] = useState(true);
  const [requestExpandTime, setRequestExpandTime] = useState<number | null>(null);
  const [requestExpandPrice, setRequestExpandPrice] = useState<number | null>(null);
  const [expandTime, setExpandTime] = useState<number | null>(null);
  const [defaultExpandTime, setDefaultExpandTime] = useState<number | null>(null);
  const [expandPrice, setExpandPrice] = useState<number | null>(null);
  const [defaultExpandPrice, setDefaultExpandPrice] = useState<number | null>(null);
  const [logoutUrl, setLogoutUrl] = useState<string | null>(null);
  const [messageCode, setMessageCode] = useState<MessageCodes>();
  const [message, setMessage] = useState<string>();
  const [isShowProviderConfirm, setIsShowProviderConfirm] = useState(false);
  const [isShowProviderConfirmDone, setIsShowProviderConfirmDone] = useState(false);
  const [isShowCustomerConfirmDone, setIsShowCustomerConfirmDone] = useState(false);
  const [isShowLast10Min, setIsShowLast10Min] = useState(false);
  const [isShowConfirmExpand, setIsShowConfirmExpand] = useState(false);
  const [isShowProviderConfirmExpandSuccess, setIsShowProviderConfirmExpandSuccess] = useState(false);
  const [isShowProviderConfirmExpandCancel, setIsShowProviderConfirmExpandCancel] = useState(false);
  const [isShowGuestConfirmExpandSuccess, setIsShowGuestConfirmExpandSuccess] = useState(false);
  const [endPointMultiDomain, setEndPointMultiDomain] = useState<string>('');
  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    createRoom,
    setCreateRoom,
    createConversation,
    setCreateConversation,
    maxParticipants,
    setMaxParticipants,
    recording,
    setRecording,
    duration,
    setDuration,
    roomName,
    setRoomName,
    userIdentity,
    setUserIdentity,
    accessHash,
    setAccessHash,
    // userId,
    // setUserId,
    userType,
    setUserType,
    connectType,
    setConnectType,
    payment,
    setPayment,
    expandTime,
    setExpandTime,
    defaultExpandTime,
    setDefaultExpandTime,
    expandPrice,
    setExpandPrice,
    defaultExpandPrice,
    setDefaultExpandPrice,
    logoutUrl,
    setLogoutUrl,
    requestExpandTime,
    setRequestExpandTime,
    requestExpandPrice,
    setRequestExpandPrice,
    messageCode,
    setMessageCode,
    message,
    setMessage,
    isShowProviderConfirm,
    setIsShowProviderConfirm,
    isShowProviderConfirmDone,
    setIsShowProviderConfirmDone,
    isShowCustomerConfirmDone,
    setIsShowCustomerConfirmDone,
    isShowLast10Min,
    setIsShowLast10Min,
    isShowConfirmExpand,
    setIsShowConfirmExpand,
    isShowProviderConfirmExpandSuccess,
    setIsShowProviderConfirmExpandSuccess,
    isShowProviderConfirmExpandCancel,
    setIsShowProviderConfirmExpandCancel,
    isShowGuestConfirmExpandSuccess,
    setIsShowGuestConfirmExpandSuccess,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user, room, hash) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
        // api側でlocalフラグを渡す(prodでは現状不要)
        const env = process.env.NODE_ENV === 'development' ? 'local1' : '';
        console.log('process.env.TARGET_ENV', process.env);
        // console.log(endPointMultiDomain);
        const query = new URLSearchParams({
          user,
          room,
          hash,
          env,
        });
        return fetch(`${endpoint}?${query}`, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      getMessage: async () => {
        const endpoint = endPointMultiDomain
          ? `${endPointMultiDomain}/api/tw/message`
          : `${process.env.REACT_APP_API_ENDPOINT}/api/tw/message`;
        const query = new URLSearchParams({
          room: roomName!,
          type: userType! || 'guest',
        });
        return fetch(`${endpoint}?${query}`, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      postExpandOffer: async (room: string, expand_time: number, expand_price: number) => {
        const endpoint = endPointMultiDomain
          ? `${endPointMultiDomain}/api/tw/expand_offer`
          : `${process.env.REACT_APP_API_ENDPOINT}/api/tw/expand_offer`;

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room, expand_time, expand_price }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();
            return jsonResponse;
          })
          .catch(err => {
            throw new Error(err);
          });
      },
      postExpandAccept: async (room: string, isCancel: boolean = false) => {
        const endpoint = endPointMultiDomain
          ? `${endPointMultiDomain}/api/tw/expand_accept`
          : `${process.env.REACT_APP_API_ENDPOINT}/api/tw/expand_accept`;

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room, result: isCancel ? 'cancel' : '' }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();
            return jsonResponse;
          })
          .catch(err => {
            throw new Error(err);
          });
      },

      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = endPointMultiDomain
          ? `${endPointMultiDomain}/api/tw/recordingrules`
          : process.env.REACT_APP_RECORDING_ENDPOINT || '/recordingrules';

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (user_identity, room_name, access_hash) => {
    setIsFetching(true);
    return contextValue
      .getToken(user_identity, room_name, access_hash)
      .then(res => {
        console.log('res', res);
        //setRoomType(res.room_type);
        //if (typeof res.default_expand_time !== 'undefined') setDefaultExpandTime(res.default_expand_time);
        //setDefaultExpandPrice(res.default_expand_price);
        setLogoutUrl(decodeURIComponent(res.logout_url));
        setUserType(res.type);
        setConnectType(res.connect_type);
        setPayment(res.payment);
        // setRoomName(res.user_name); // TODO 今mockだからnamesetするとmessageで500エラーになっちゃう
        setAccessHash(res.access_hash);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getMessage = async () => {
    return await contextValue
      .getMessage()
      .then(res => {
        if (res.message_code) {
          setMessageCode(res.message_code);
          setMessage(res.message_text);
          if (res.price) {
            setRequestExpandPrice(res.price);
          }
          if (res.time) {
            setRequestExpandTime(res.time);
          }
        }
        return res; // resをそのまま返す
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const query = queryString.parse(window.location.search);

  const isNotUndefinedOrNull = (val: any) => {
    return val !== null && val !== undefined;
  };

  useEffect(() => {
    if (isNotUndefinedOrNull(query.create_room) && ['0', '1'].includes(query.create_room as string)) {
      setCreateRoom(Boolean(Number(query.create_room)));
    }
    if (isNotUndefinedOrNull(query.create_conversation) && ['0', '1'].includes(query.create_conversation as string)) {
      setCreateConversation(Boolean(Number(query.create_conversation)));
    }
    if (isNotUndefinedOrNull(query.max_participants)) {
      setMaxParticipants(Number(query.max_participants));
    }

    if (isNotUndefinedOrNull(query.user_id)) {
      // setUserId(Number(query.user_id) as userTypes);
    }
    if (isNotUndefinedOrNull(query.name)) {
      setUserIdentity(query.name as string);
    }
    if (isNotUndefinedOrNull(query.room)) {
      setRoomName(query.room as string);
    }
    if (isNotUndefinedOrNull(query.hash)) {
      setAccessHash(query.hash as string);
    }
    if (isNotUndefinedOrNull(query.recording) && ['0', '1'].includes(query.recording as string)) {
      setRecording(Boolean(Number(query.recording)));
    }
    if (isNotUndefinedOrNull(query.duration)) {
      console.log('query.duration', query.duration);
      setDuration(Number(query.duration));
    }
    if (isNotUndefinedOrNull(query.domain)) {
      const endpoint = 'https://' + query.domain;
      setEndPointMultiDomain(endpoint);
    }
  }, []);

  useEffect(() => {
    console.log('useEffect: messageCode', messageCode);
    switch (messageCode) {
      // 10分前dialogをshow
      case MESSAGE_CODES.BEFORE_10_MIN:
        setIsShowLast10Min(true);
        break;
      // Guestで延長確認dialogをshow
      case MESSAGE_CODES.GUEST_RECEIVED_EXPAND_REQUEST:
        setIsShowConfirmExpand(true);
        break;
      // Providerで延長成功dialogをshow
      case MESSAGE_CODES.PROVIDER_EXPAND_SUCCESS:
        setIsShowProviderConfirmExpandSuccess(true);
        break;
      // Providerで延長成功dialogをshow
      case MESSAGE_CODES.PROVIDER_EXPAND_CANCEL:
        setIsShowProviderConfirmExpandCancel(true);
        break;
      // Guestで延長成功dialogをshow
      case MESSAGE_CODES.GUEST_EXPAND_SUCCESS:
        setIsShowGuestConfirmExpandSuccess(true);
        break;
    }
  }, [messageCode, setMessageCode]);

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, getMessage, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
